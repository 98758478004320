import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatLink } from '../../../shared/utility';
import Button from '../../UI/Button/Button';
import IconNewUI from './IconNewUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
/* UI of New full page*/
const NewUI = props => {

    const keys = Object.keys(props.new);
    let content = keys.map((key) => {
        const k = key.split('-');
        switch (k[0]) {
            case 'title':
                return <h1 className="green h4 fw-700 text-uppercase mb-2" key={props.key}>
                    <Trans key={props.key} t={props.t} i18nKey={props.new[key]} /></h1>;
            case 'h2Ttile':
                return <h2 className="green h5 fw-700 text-uppercase mb-0 mt-5" key={props.key}>{props.new[key]}</h2>;
            case 'fullDate':
                return <p className="full-date mb-5" key={props.key}>{props.new[key]}</p>;
            case 'text':
            case 'quote':
                return (
                    <p key={props.key} className={k[0] === 'quote' ? 'fw-700 font-italic' : ''}>
                        <Trans
                            key={props.key}
                            t={props.t}
                            i18nKey={"blog:news." + props.id + '.content.' + key}
                            components={{
                                homeLink: <Link to={props.t('routes:homepage.path') + '#top'} />,
                                email: <Link to="#" onClick={(e) => {
                                    e.preventDefault();
                                    window.location = 'mailto:qualidade@sysmatch.com';
                                }} />,
                                linkJobs: <Link to={props.t('routes:jobs.path') + '#top'} target="_blank" />,
                                linkNearshoring: <Link to={props.t('routes:nearshoring.path')} target="_blank" />,
                                development: <Link to={props.t('routes:development.path')} target="_blank" />,
                                linkFrontend: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.10.content.url'))} target="_blank" />,
                                linkBackend: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.11.content.url'))} target="_blank" />,
                                linkOutsorcing: <Link to={props.t('routes:outsourcing.path')} target="_blank" />,
                                linkCareerIT: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.12.content.url'))} target="_blank" />,
                                linkUniversity: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.13.content.url'))} target="_blank" />,
                                linkCareerTechnology: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.18.content.url'))} target="_blank" />,
                                linkIA: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.24.content.url'))} target="_blank" />,
                                linkMachine: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.25.content.url'))} target="_blank" />,
                                linkSecurity: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.23.content.url'))} target="_blank" />,
                                linkPowerBI: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.20.content.url'))} target="_blank" />,
                                linkBigData: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.32.content.url'))} target="_blank" />,
                                linkJava: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.39.content.url'))} target="_blank" />,
                                linkProductOwner: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.41.content.url'))} target="_blank" />,
                                linkDataAnalysis: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.29.content.url'))} target="_blank" />,
                                linkCOBOL: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.36.content.url'))} target="_blank" />,
                                linkTIOBE: <a href="https://www.tiobe.com/tiobe-index/" target="_blank" ></a>,
                                a: <a></a>,
                                em: <em></em>,
                                b: <b></b>,
                                open: "<",
                                close: ">"
                            }} />
                    </p>
                );
            case 'h3Ttile': return <h3 className="black h6 fw-700 text-uppercase mt-5" key={props.key}>
                <Trans
                    key={props.key}
                    t={props.t}
                    i18nKey={"blog:news." + props.id + '.content.' + key}
                    components={{
                        em: <em></em>
                    }} />
            </h3>;
            case 'h4Ttile': return <div className='d-flex align-items-center mt-3 mb-2s'>
                <div className='arrow-container'>
                    <div className='arrow'>
                        <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </div>
                </div>
                <h4 className="black h6 fw-700 text-uppercase orange m-0" key={props.key}>
                    <Trans
                        key={props.key}
                        t={props.t}
                        i18nKey={"blog:news." + props.id + '.content.' + key}
                        components={{
                            em: <em></em>
                        }} />
                </h4>
            </div>;
            case 'h5Title': return <div className='d-flex align-items-center mt-3 mb-2s'>
                <h5 className="black h6 fw-700 text-uppercase m-0" key={props.key}>
                    <Trans
                        key={props.key}
                        t={props.t}
                        i18nKey={"blog:news." + props.id + '.content.' + key}
                        components={{
                            em: <em></em>,
                        }} />
                </h5>
            </div>;
            case 'image':
                const img = require('../../../assets/images/blog/' + props.new[key].name + ".jpg");
                let imgWebp = null;
                if (props.new[key].webpName) {
                    imgWebp = require('../../../assets/images/blog/' + props.new[key].webpName + ".webp");
                }

                return <div key={props.key} className="wrap-img mb-5">
                    {props.srcWebp || (imgWebp && imgWebp) ?
                        <picture>
                            <source srcSet={props.srcWebp ? props.srcWebp : imgWebp} type="image/webp" />
                            <source src={props.src ? props.src : img} type="image/png" />
                            <img src={props.src ? props.src : img} alt={props.alt} className="shadow-blog mb-4" />
                        </picture> :
                        <img className={props.new[key].class} src={img} alt={props.new[key].alt} />
                    }

                </div>;
            case 'techImage':
                const techimg = require('../../../assets/images/blog/' + props.new[key].name + ".png");
                let techimgWebp = null;
                if (props.new[key].webpName) {
                    techimgWebp = require('../../../assets/images/blog/' + props.new[key].webpName + ".webp");
                }

                return <div key={props.key} className="wrap-img mb-5">
                    {props.srcWebp || (techimgWebp && techimgWebp) ?
                        <picture>
                            <source srcSet={props.srcWebp ? props.srcWebp : techimgWebp} type="image/webp" />
                            <source src={props.src ? props.src : techimg} type="image/png" />
                            <img src={props.src ? props.src : techimg} alt={props.alt} className="shadow-blog mb-4" />
                        </picture> :
                        <img className={props.new[key].class} src={techimg} alt={props.new[key].alt} />
                    }

                </div>;
            case 'ul':
                const itens = Object.values(props.new[key]);
                return <ul className="p">{
                    itens.map((item, i) =>
                        <li key={i}>
                            <Trans
                                key={props.key}
                                t={props.t}
                                i18nKey={item}
                                components={{
                                    b: <b></b>,
                                    linkJobs: <Link to={props.t('routes:jobs.path') + '#top'} target="_blank" />,
                                    linkNearshoring: <Link to={props.t('routes:nearshoring.path')} target="_blank" />,
                                    development: <Link to={props.t('routes:development.path')} target="_blank" />,
                                    linkFrontend: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.10.content.url'))} target="_blank" />,
                                    linkBackend: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.11.content.url'))} target="_blank" />,
                                    linkOutsorcing: <Link to={props.t('routes:outsourcing.path')} target="_blank" />,
                                    linkCareerIT: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.12.content.url'))} target="_blank" />,
                                    linkUniversity: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.13.content.url'))} target="_blank" />,
                                    linkCareerTechnology: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.18.content.url'))} target="_blank" />,
                                    linkIA: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.24.content.url'))} target="_blank" />,
                                    linkMachine: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.25.content.url'))} target="_blank" />,
                                    linkSecurity: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.23.content.url'))} target="_blank" />,
                                    linkPowerBI: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.20.content.url'))} target="_blank" />,
                                    linkBigData: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.32.content.url'))} target="_blank" />,
                                    linkJava: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.39.content.url'))} target="_blank" />,
                                    linkProductOwner: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.41.content.url'))} target="_blank" />,
                                    linkDataAnalysis: <Link to={props.t('routes:blog.path') + '/' + formatLink(props.t('blog:news.29.content.url'))} target="_blank" />,
                                    linkTIOBE: <a href="https://www.tiobe.com/tiobe-index/" target="_blank" ></a>,
                                }} />
                        </li>
                    )}
                </ul>;


            case 'table':
                let titleRow = props.new[key]["header"] ? <tr>{Object.values(props.new[key]["header"]).map((items, i) => {
                    return (
                        <th key={i}><p>{items}</p></th>
                    )
                })}</tr> : ""

                let contentRow = props.new[key]["rows"] ? Object.values(props.new[key]["rows"]).map((items, i) => {
                    return (
                        <tr key={i}>{Object.values(items).map((content) => {
                            return <td>
                                <p className='m-0'>
                                    <Trans t={props.t} i18nKey={content} components={{
                                        bold: <b></b>,
                                        code: <code></code>,
                                        open: "<",
                                        close: ">",
                                        start: <span className='d-inline-block w-100 text-start'></span>
                                    }} />
                                </p>
                            </td>
                        })}</tr>
                    )
                }) : ""
                return <table className='news-table'>
                    {titleRow}
                    {contentRow}
                </table>;
            case 'btnJobs':
                return <Link to={props.t('routes:jobs.path') + '#top'} >
                    <Button key={props.key} btnClasses="p mx-0 mt-4 p-l h4 fw-600 text-uppercase">
                        {props.new[key]}
                    </Button>
                </Link>;
            case 'code':
                return <code onCopy={(e) => {
                    navigator.clipboard.writeText(document.getSelection() + e.target.innerText + props.t('blog:codeCopy', { 'interpolation': { 'escapeValue': false } }))
                }}>
                    <p>
                        <Trans
                            t={props.t}
                            i18nKey={props.new[key]}
                            components={{
                                open: "<",
                                close: ">",
                                b: <b></b>
                            }} />
                    </p>
                </code>;
            case 'separator':
                return <span className='separator'></span>;
            default: return null;
        }
    });
    let popularNews = Object.entries(props.news);
    let index = 0;

    for (let i = 0; i < popularNews.length; i++)
        if (popularNews[i][0] === props.id)
            index = i

    popularNews.splice(index, 1);
    popularNews.reverse();
    popularNews.splice(6, Number.MAX_VALUE);
    popularNews = popularNews.map((popularNew) => <li key={popularNew[0]}><Link to={popularNew[1].link ? popularNew[1].link : props.t('routes:blog.path') + '/' + formatLink(popularNew[1].content.url)} target="_blank" ><Button btnClasses="p-s ml-0 h5 fw-600">+</Button></Link><p>{<Trans key={props.key} t={props.t} i18nKey={popularNew[1].content.title} />}</p></li>)
    return (
        <div
            id={props.id}
            className='newUI mb-lg-5 pb-lg-5'>
            <Row>
                <Col lg={7}>
                    {content}
                    <IconNewUI t={props.t} title={props.new.title} thirdPerson={props.new.thirdPerson} />
                </Col>
                <Col lg={{ span: 4, offset: 1 }}>
                    <div className="popularNews">
                        <div className="title-triangle white top-md"></div>
                        <h2 className="h5 white fw-600 text-uppercase">{props.t('blog:title-aside')}</h2>
                        <ul>
                            {popularNews}
                        </ul>
                    </div>
                    <Row className="justify-content-center mt-5">
                        <div>
                            <Link className="position-relative" to={props.t('routes:blog.path') + '#top'}>
                                <Button btnClasses="p-l p fw-600 text-uppercase mt-4 mt-lg-0 ml-0">{props.t('blog:btn-back')}</Button>
                            </Link>
                        </div>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default NewUI;